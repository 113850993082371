<script>
  import VueSlickCarouselc from "vue-slick-carousel";
  import TestimonialVideo from "@/components/web/TestimonialVideo.vue";
  import store from "../../store/index.js";
  import router from "../../router";
  import api from "@/api/index.js";
  export default {
    components: { VueSlickCarouselc, TestimonialVideo },
    props: {
      to: Object,
    },
    data: function () {
      return {
        loaded: true,
        data: null,
        title: "",
        language: null,
        infoModel: [],
        currentVideo: null,
        showPrevArrow: false,
        clickCountNext: 0,
        clickCountPrev: 0,
        settings: {
          dots: true,
          arrows: true,
          slidesToShow: 4,
          slidesToScroll: 3,
          adaptiveHeight: true,
          edgeFriction: 1,
          speed: 750,
          infinite: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                arrows: true,
                dots: false,
                speed: 500,
                autoplay: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
              },
            },
          ],
        },
      };
    },
    methods: {
      changeCurrentVideo(currentVideo) {
        if (this.currentVideo) {
          const auxInfoModel = this.infoModel;
          const indexVideo = auxInfoModel.findIndex((item) => item._id === this.currentVideo);

          if (indexVideo !== -1) {
            auxInfoModel[indexVideo] = {
              ...auxInfoModel[indexVideo],
              showPoster: false,
              playingVideo: false,
            };
            this.infoModel = [...auxInfoModel];
          }
        }
        this.currentVideo = currentVideo;
      },
      onAfterChange() {
        document.querySelectorAll(".video__testimonial").forEach((video) => {
          video?.pause();
        });
      },
      async getTestimonials() {
        if (store.getters["iflanguage"]) {
          const { testimonials } = await store.getters["dataLang"];
          const { data } = await api.get(`${process.env.VUE_APP_API_HOST}newTestimonials`);

          this.infoModel = data.testimonials.map((testimonial) => ({
            ...testimonial,
            showPoster: false,
            playingVideo: false,
          }));

          this.title = testimonials.title;
          this.loaded = false;

          if (this.to.hash === "#testimonials") {
            await router.push({ hash: "" });
            await new Promise((resolve) => setTimeout(resolve, 1000));
            await router.push({ hash: this.to.hash });

            window.addEventListener("load", () => {
              const testimonialsElement = document.getElementById("testimonials");
              if (testimonialsElement) {
                testimonialsElement.classList.add("testimonials-animation");
                const offsetTop = testimonialsElement.getBoundingClientRect().top + window.scrollY;
                const windowHeight = window.innerHeight || document.documentElement.clientHeight;
                const elementHeight = testimonialsElement.offsetHeight || testimonialsElement.clientHeight;
                const scrollToY = offsetTop - (windowHeight - elementHeight) / 2;

                if (window.scrollTo) {
                  window.scrollTo({
                    top: scrollToY,
                    behavior: "smooth",
                  });
                } else {
                  window.scroll(0, scrollToY);
                }
              }
            });
          }
        }
      },
    },
    computed: {
      initData: async function () {
        this.getTestimonials();
      },
    },
  };
</script>

<template>
  <section class="testimonials" id="testimonials" v-bind="initData">
    <div v-if="infoModel" class="testimonials__container">
      <div class="testimonials__title">
        <h2 class="testimonials__title-text sec-font">{{ title }}</h2>
      </div>
      <div class="skeleton-wrapper" v-show="infoModel.length <= 0">
        <div v-for="n in 4" :key="n" class="skeleton-item"></div>
      </div>
      <div class="testimonials__wrapper" v-if="!loaded">
        <VueSlickCarouselc
          :lazyLoad="'progressive'"
          v-bind="settings"
          class="testimonials__wrapper-slide"
          style="width: 100%; height: 100%"
          :arrows="true"
          :infinite="false"
          v-on:afterChange="onAfterChange">
          <template #prevArrow>
            <div class="custom-arrow prev-arrow">
              <iconic class="arrow" name="prevArrowTransparent" />
            </div>
          </template>
          <TestimonialVideo v-for="(model, idx) in infoModel" :key="idx" :model="model" ref="videoTestimonial" @changeCurrentVideo="changeCurrentVideo" :currentVideo="currentVideo" />
          <template #nextArrow>
            <div class="custom-arrow next-arrow">
              <iconic class="arrow" name="nextArrowTransparent" />
            </div>
          </template>
        </VueSlickCarouselc>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  .testimonials {
    .skeleton-wrapper {
      justify-content: center;
      width: 476px;
      height: 100%;
    }
    .skeleton-item {
      width: 476px;
      height: 850px;
      background-color: #111010;
      animation: skeleton-loading 1.5s ease-in-out infinite alternate;
    }

    @keyframes skeleton-loading {
      0% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }

    img,
    video {
      max-width: 100%;
      max-height: 100%;
    }
    margin-top: 45px;
    width: 100%;
    .testimonials__container {
      min-height: 150px;
      height: 100%;
    }
    .testimonials__title-text {
      margin-top: 45px;
      margin-bottom: 20px;
      font-size: 42px;
      text-align: center;
      color: $primary-color;
      font-weight: normal;
    }

    .testimonials__wrapper-slide {
      cursor: pointer;
    }
    .slick-track {
      @include Flex(row, center, center);
      background: #000;
    }
    .slick-list {
      height: inherit !important;
    }
    .slick-dots {
      bottom: -55px;
    }
    .slick-dots li button {
      font-size: 15px;
      color: $primary_color;
    }
    .slick-dots li {
      margin: 0 15px !important;
    }
    .slick-dots li button {
      font-size: 15px;
      color: transparent;
      width: 29px;
      height: 5px;
      border-radius: 10px;
      background-color: #d9d9d980;
    }

    .slick-dots li.slick-active button {
      background-color: $primary_color;
    }

    .slick-dots li button:before {
      color: transparent;
      opacity: 0;
    }
    .slick-arrow {
      position: absolute;
      z-index: 400;
      top: 94%;
    }
    .slick-prev {
      left: 3%;
    }
    .slick-next {
      left: 19%;
    }
    .slick-prev,
    .slick-next {
      z-index: 1;
      height: 100%;
      width: 70px;
    }
    .slick-prev::before,
    .slick-next::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      &:hover {
        color: $primary-color;
      }
    }
    .custom-arrow {
      width: 50px;
      height: 70px;
      .iconic {
        font-size: 40px;
        color: white;
        .arrow {
          color: #bd0909;
        }
      }
    }
    @media screen and (min-width: 768px) {
      .custom-arrow {
        top: 50%;
        &:hover .iconic {
          color: $primary-color;
        }
      }
      .slick-next,
      .slick-prev {
        .iconic {
          svg {
            width: 50px;
            height: 50px;
          }
        }
      }
      .slick-prev {
        left: 1%;
        color: $primary-color;
      }
      .slick-next {
        left: 93%;
      }
    }
    @media screen and (min-width: 1024px) {
      .slick-prev {
        left: 1%;
        color: $primary-color;
      }
      .slick-next {
        left: 94%;
      }
    }
    @media screen and (min-width: 1440px) {
      .slick-next {
        left: 96%;
      }
    }
  }
</style>
