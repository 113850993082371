<script>
  import store from "../../store/index.js";
  import VueSlickCarousel from "vue-slick-carousel";

  export default {
    components: {
      VueSlickCarousel,
    },
    props: { isLanding: Boolean },
    data() {
      return {
        isLoading: true,
        title: null,
        loadingImage: {},
        info: null,
        locale: null,
        slides: null,
        resources: [],
        keySlider: 0,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 1,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: 1250,
              settings: {
                arrows: false,
              },
            },
          ],
        },
      };
    },
    methods: {
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      urlEncodeB64: function (url) {
        return btoa(url);
      },
      getLanguage: async function () {
        try {
          if (store.getters["iflanguage"]) {
            this.locale = await store.getters["locale"];
            let dataLang = await store.getters["dataLang"];
            this.slides = dataLang.sliderImages;
            this.orderSlide(dataLang.resource);
            this.isLoading = false;
          }
        } catch (error) {
          console.error(error.message);
        }
      },
      getRouterLink(video) {
        if (video) {
          return {
            path: "watch",
            query: { v: this.urlEncodeB64(video), backto: "/" },
          };
        } else {
          return {};
        }
      },
      orderSlide(resources) {
        let newsRecources = resources;
        newsRecources.slider.desktop = newsRecources.slider?.desktop
          .filter((slide) => slide.status)
          .sort((a, b) => {
            if (a.index > b.index) return 1;
            if (a.index < b.index) return -1;
            return 0;
          });
        newsRecources.slider.mobile = newsRecources.slider.mobile
          .filter((slide) => slide.status)
          .sort((a, b) => {
            if (a.index > b.index) return 1;
            if (a.index < b.index) return -1;
            return 0;
          });
        this.info = newsRecources;
        this.resources = this.info.slider;
        this.keySlider++;
      },
      isVideo(file) {
        return file.type == "video" ? file : false;
      },
      getResources(device) {
        return this.resources[device];
      },
      async getLoadingImage() {
        const language = localStorage.getItem("user-language");
        const { data } = await this.$axios.get("slider1aExperience");

        const index = language === "es" ? 0 : 1;

        this.title = data.items[index].title.homeSlideTitle;
        this.loadingImage = { desktop: data.items[0].slider.desktop[0].file, mobile: data.items[0].slider.mobile[0].file };
      },

      getTitle() {
        return this.info?.title ?? this.title;
      },
    },
    computed: {
      hasResources: function () {
        return this.resources.length > 0;
      },

      initComponent: async function () {
        await this.getLanguage();
        if (this.isLoading) {
          await this.getLoadingImage();
        }
        document.addEventListener("keyup", (event) => {
          if (event.key === "ArrowLeft") {
            this.$refs.slider?.prev();
          }
          if (event.key === "ArrowRight") {
            this.$refs.slider?.next();
          }
        });
      },
    },
  };
</script>
<template>
  <section class="homeSlider-section" v-bind="initComponent">
    <div v-if="locale && resources">
      <div class="homeSlider-section-title section-title" v-if="!isLanding">
        <h2 class="sec-font">{{ getTitle() }}</h2>
      </div>
      <div class="homeSlider" v-if="info">
        <VueSlickCarousel v-bind="settings" ref="slider" :key="keySlider">
          <template #prevArrow="">
            <div class="custom-arrow">
              <iconic name="prevArrowTransparent" />
            </div>
          </template>
          <div
            v-for="(slide, idx) in getResources($screen.width > 768 ? 'desktop' : 'mobile')"
            :class="['slide', $screen.width > 768 ? 'desktop-slide' : 'mobile-slide', isLanding ? 'landing' : '']"
            :key="idx">
            <div class="slide-container">
              <div class="slide-image" v-if="!isLanding">
                <picture v-if="!isVideo(slide)">
                  <img :src="urlCloudFront(slide.file)" :alt="''" loading="lazy" />
                </picture>
                <div class="video" v-else>
                  <router-link :to="getRouterLink(slide.file)" block>
                    <div class="wrapper-btn">
                      <img class="btn-play" src="../../assets/img/btn-play.svg" alt="btn" />
                    </div>
                    <img class="event-desktop-image" :src="urlCloudFront(slide.cover)" :alt="'image-' + idx" prevent block loading="lazy" />
                  </router-link>
                </div>
              </div>

              <picture class="slide-image" v-else>
                <source :srcset="urlCloudFront(slide.landingImg)" media="(min-width: 768px)" :alt="slide.description" />
                <img :src="urlCloudFront(slide.mobileImg)" :alt="slide.description" loading="lazy" />
              </picture>
            </div>
          </div>
          <template #nextArrow="">
            <div class="custom-arrow">
              <iconic name="nextArrowTransparent" />
            </div>
          </template>
        </VueSlickCarousel>
        <slot name="btn"></slot>
      </div>
    </div>
    <div v-else class="image-loading">
      <div class="homeSlider-section-title section-title" v-if="!isLanding">
        <h2 class="sec-font">{{ getTitle() }}</h2>
      </div>
      <div class="slide-image" v-if="$screen.width > 768">
        <picture>
          <img :src="urlCloudFront(loadingImage.desktop)" :alt="'Imagen Carga'" loading="lazy" />
        </picture>
      </div>
      <div class="slide-image" v-else>
        <picture>
          <img :src="urlCloudFront(loadingImage.mobile)" :alt="'Imagen Carga'" loading="lazy" />
        </picture>
      </div>
    </div>
  </section>
</template>
<style lang="scss">
  .homeSlider-section {
    position: relative;
    .sec-font {
      margin-bottom: 20px;
    }
    &-title {
      margin: calc(#{$header_height}) $mpadding 0 $mpadding;
      text-align: center;
      color: $primary-color;
      display: block;
    }
    .slick-slide > div {
      position: relative;
      &::after {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 20;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 1.69%, rgba(0, 0, 0, 0) 11.05%, rgba(0, 0, 0, 0) 60.99%, rgba(0, 0, 0, 0.15) 90%, rgba(0, 0, 0, 1) 100%);
      }
    }
    .slide {
      max-height: 885px;
      &-image {
        img,
        video {
          height: 100%;
          width: 100%;
          pointer-events: none;
        }
        .video {
          .wrapper-btn {
            width: 80px;
            height: 80px;
            background: $primary_color;
            border-radius: 50%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            border: none;
            z-index: 200;
            cursor: pointer;
            .btn-play {
              max-width: 50%;
              position: absolute;
              left: 55%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
      &:not(.landing) {
        img {
          @media (min-width: 1800px) {
            transform: translateY(-7%);
          }
        }
      }
    }

    .landing {
      position: relative;
      max-height: 686px;
      .slide-container {
        height: 100%;
      }
    }

    .slick-prev,
    .slick-next {
      z-index: 1;
      height: 100%;
      width: 27px;
    }
    .slick-prev::before,
    .slick-next::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .slick-prev {
      left: 30px;
      height: 100px;
      padding: 0 40px;
      border-radius: 1rem;
    }
    .slick-next {
      right: 30px;
      height: 100px;
      padding: 0 40px;
    }

    .slick-dots {
      bottom: $mpadding;
    }
    .slick-dots li {
      margin: 0 5px !important;
    }
    .slick-dots li button {
      font-size: 15px;
      color: transparent;
      width: 20px;
      height: 5px;
      border-radius: 10px;
      background-color: #d9d9d980;
    }

    .slick-dots li.slick-active button {
      background-color: $primary_color;
    }

    .slick-dots li button:before {
      color: transparent;
      opacity: 0;
    }
    .mobile-slide {
      display: block;
    }
    .desktop-slide {
      display: none;
    }
    @include Desktop {
      .slick-dots {
        bottom: $mpadding * 3;
      }
      .slick-dots li {
        margin: 0 15px !important;
      }
      .slick-dots li button {
        width: 29px;
      }
      .mobile-slide {
        display: none;
      }
      .desktop-slide {
        display: block;
      }
    }
    .slick-arrow {
      top: 93%;
    }
    .slick-prev {
      left: 78%;
    }
    .slick-next {
      right: 9%;
    }
    .custom-arrow {
      margin: auto;
      &:hover {
        .iconic {
          color: #bd0909;
        }
      }
      .iconic {
        font-size: 80px;
        text-align: center;
        color: white;
        .arrow {
          color: white;
        }
      }
    }
    @media (max-width: 430px) {
      .slide-image {
        img {
          height: 500px;
          object-fit: cover;
        }
      }
    }
  }
</style>
