<template>
  <section class="section welcome-section" id="home" v-bind="initData">
    <div v-if="info && locale" v-cloak>
      <WelcomeVideo
        :url="urlCloudFront(info.desktopBanners[0].mediaLink)"
        :bg="urlCloudFront(info.desktopBanners[0].coverVideo)"
      />

      <VueSlickCarousel
        v-bind="settings"
        class="static"
        @afterChange="manageTextVisibility"
      >
        <div v-for="(resource, cidx) in resources" :key="cidx">
          <div
            class="static__resource"
            :style="`background-image:url('${urlCloudFront(resource.file)}')`"
            v-if="resource.type == 'image'"
          ></div>
          <WelcomeVideo
            :url="urlCloudFront(resource.file)"
            :bg="urlCloudFront(resource.cover)"
            v-else
          />
        </div>
      </VueSlickCarousel>
      <div class="static-content">
        <div :class="`static-content-advice `" v-if="searchIndex">
          <div class="static-content-advice-first">
            <h2 class="static-content-advice-txt" id="animation">
              {{ info.mobileBanners[0].descriptions[0] }}
            </h2>
          </div>
          <div class="txtWrap">
            <div class="static-content-advice-txt typewriterWrap">
              <div
                class="typewriter"
                :data-1="info.mobileBanners[0].descriptions[1]"
                :data-2="info.mobileBanners[0].descriptions[2]"
              />
            </div>
          </div>
          <div class="txtWrap">
            <h2 class="static-content-advice-txt">
              {{ info.mobileBanners[0].descriptions[3] }}
            </h2>
          </div>
          <div class="txtWrap">
            <h2 class="static-content-advice-txt">
              {{ info.mobileBanners[0].descriptions[4] }}
            </h2>
          </div>
        </div>
        <div class="static-content-options">
          <signup-button
            class="btnMovil"
            :title="info.mobileBanners[0].descriptions[5]"
          ></signup-button>
          <router-link
            class="sec-button btnMovil"
            :to="{
              path: 'watch',
              query: { v: urlEncodeB64(info.mobileBanners[0].mediaLink) },
            }"
          >
            {{ info.mobileBanners[0].descriptions[6] }}
          </router-link>
        </div>
      </div>
      <div :style="`background-image:url('${bannerImage}')`"></div>
    </div>
  </section>
</template>
<script>
import store from "../../store/index.js";
import SignupButton from "./SignupButton.vue";
import ButtonRadar from "@/components/buttons/ButtonRadar.vue";
import WelcomeVideo from "@/components/widgets/WelcomeVideo.vue";
import VueSlickCarousel from "vue-slick-carousel";

export default {
  components: {
    SignupButton,
    ButtonRadar,
    WelcomeVideo,
    VueSlickCarousel,
  },
  data() {
    return {
      info: null,
      locale: null,
      bannerImage: "",
      resources: [],
      currentIndex: 0,
      settings: {
        arrows: false,
        dots: false,
        infinite: true,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 300,
        fade: true,
        cssEase: "linear",
        autoplay: true,
        autoplaySpeed: 10000,
      },
    };
  },
  methods: {
    urlCloudFront(link) {
      return this.$global.newUrl(link);
    },
    getLanguage: async function () {
      if (store.getters["iflanguage"]) {
        this.locale = await store.getters["locale"];
        let dataLang = await store.getters["dataLang"];
        this.info = dataLang.banners;
        this.orderBanner(this.info.mobileBanners[0]);
      }
    },
    orderBanner(resources) {
      let newsRecources = resources.bannerHome;
      newsRecources = newsRecources
        .filter((resource) => resource.status)
        .sort((a, b) => {
          if (a.index > b.index) return 1;
          if (a.index < b.index) return -1;
          return 0;
        });
      this.resources = newsRecources;
    },
    urlEncodeB64: function (url) {
      return btoa(url);
    },
    manageTextVisibility() {
      document.querySelectorAll(".static .slick-slide").forEach((item, i) => {
        if (item.classList.contains("slick-current")) {
          this.currentIndex = i;
        }
      });
    },
  },
  computed: {
    initData: async function () {
      this.getLanguage();
    },
    searchIndex: function () {
      return this.info.mobileBanners[0].showTexts?.includes(this.currentIndex);
    },
  },
};
</script>
<style lang="scss">
.static {
  &__resource {
    min-height: 80vh;
    background-position: top center;
    background-size: cover;
  }
  video {
    width: 100%;
    height: 100%;
  }
}
.welcome-section {
  [v-cloak] {
    display: none;
  }
  position: relative;
  .top-video {
    display: none;
  }
  .hide-slide {
    opacity: 0;
  }
  .static {
    transition: background-image 1000ms ease 0s;
    display: block;
    position: relative;
    min-height: 100%;
    background-position: top center;
    background-size: cover;
    .top-video {
      display: block;
    }
    &-content {
      position: absolute;
      bottom: 8vh;
      left: 0;
      &-advice {
        transition: opacity 0.5s ease-in-out;
        margin-bottom: $mpadding * 2;
        &-first {
          animation: fadeInDown 1s ease-in-out;
        }
        &-txt {
          display: inline-block;
          color: $primary_color;
          background-color: $white;
          margin-bottom: $mpadding * 0.3;
          font-size: 110%;
          font-weight: bold;
          padding: 2px $mpadding * 0.75 2px $mpadding * 1.5;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          animation: fadeInDown 1s ease-in-out;
          &:not(.typewriterWrap) {
            background-color: $primary_color;
            color: $white;
            animation: dropInX 1s ease-in-out;
          }
        }
      }
      &-options {
        padding: 0 10px 0 $mpadding * 1;
        .btnMovil {
          border-radius: 22px;
        }
        .sec-button {
          color: $primary_color;
          margin-left: $mpadding;
          background-color: $white;
        }
      }
    }
  }

  @media (min-width: $tablet_width) {
    .static {
      display: none;
      &-content {
        display: none;
      }
    }
    .top-video {
      display: block;
    }
  }

  @media (max-width: 375px) {
    .static {
      &__resource {
        min-height: 100vh;
      }
      .top-video {
        display: block;
      }
      &-content {
        bottom: 10%;
      }
    }
  }
}
</style>
