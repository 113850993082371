<template>
  <section class="present-section-mobile" id="" v-bind="initData">
    <div class="present-section-container-all" v-if="info">
      <h2 class="present-section-slide-title sec-font main-title" bold>{{ info.title ? info.title : "" }}</h2>
      <VueSlickCarousel v-bind="settings" @beforeChange="handleSwipe">
        <div class="section-slide-page" v-for="(post, idx) in info.news" :key="idx">
          <router-link class="present-section-container" router-link :to="{ path: '/watch-text', query: { datapost: post.name } }" block>
            <div :class="`present-section-container-btn ${post.isNewVideo ? 'section-new' : ''}`">
              <div class="wrapper-btn"><img class="btn-play" src="../../assets/img/btn-play.svg" alt="btn" /></div>
              <img class="present-section-image" :src="urlCloudFront(post.pictureLink)" :alt="'image-' + idx" prevent block loading="lazy" />
              <p class="present-section-counter">{{ idx + 1 }}/{{ info.news.length }}</p>
            </div>
            <div class="desc">
              <div class="present-section-slide-title">
                <h3 class="sec-font sub">{{ post.title }}</h3>
              </div>
              <div class="container-text" :presentSection="`carouselText${idx}`">
                <p class="text">
                  {{ getTextSize(post.describe, 300, idx) }}
                </p>
              </div>

              <span class="icon-view-more"> </span>
            </div>
          </router-link>
        </div>
      </VueSlickCarousel>
      <div v-if="btnSignUpText" class="sign-up-event">
        <signup-button class="sign-up-event-link" mclass="block" :title="btnSignUpText"></signup-button>
      </div>
    </div>
  </section>
</template>

<script>
  import store from "../../store/index.js";
  import VueSlickCarousel from "vue-slick-carousel";
  import SignupButton from "./SignupButton.vue";
  export default {
    components: { VueSlickCarousel, SignupButton },
    props: {
      title: String,
      moreTitle: String,
      moreTo: String,
      products: Array,
      cat_id: String,
      isInView: Boolean,
    },
    data() {
      return {
        info: null,
        locale: "",
        isMore: true,
        keyCarousel: 10,
        index: null,
        btnSignUpText: null,
      };
    },
    computed: {
      initData: async function () {
        await this.getLanguage();
      },
      settings() {
        return {
          arrows: true,
          slidesToShow: 1,
          mobileFirst: true,
          adaptiveHeight: true,
          autoplay: this.isInView,
          autoplaySpeed: 4000,
          dots: true,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
              },
            },
            {
              breakpoint: 500,
              settings: {
                dots: false,
              },
            },
          ],
        };
      },
    },
    methods: {
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      getLanguage: async function () {
        if (store.getters["iflanguage"]) {
          let { testimonials } = await store.getters["locale"];
          this.locale = testimonials;
          let dataLang = await store.getters["dataLang"];
          this.info = dataLang.news;
        }
      },
      urlEncodeB64: function (url) {
        return btoa(url);
      },
      getShortDesc: function (text, limit = 100) {
        return this.$global.getShort(text, limit);
      },
      tgTestimonial(index, text) {
        this.focusView();
        this.isMore = !this.isMore;
        const elms = document.querySelectorAll(`div[presentsection=carouselText${index}]`);
        for (const elm of elms) {
          elm.innerText = this.getTextSize(text, 380, index);
        }
      },
      getTextSize(text, limit, index) {
        if (this.isMore) {
          return this.$global.getShort(text, limit);
        }
        return text;
      },
      handleSwipe(event, slick, currentSlide) {
        const allCarousel = [];
        if (!this.isMore) {
          this.focusView();
          if (!this.isMore) {
            this.$router.push("/#present");
          }
          this.isMore = true;
          for (let i = 0; i < this.info.news.length; i++) {
            allCarousel.push(document.querySelector(`div[presentsection=carouselText${i}]`));
          }
          for (const text of allCarousel) {
            text.innerText = this.$global.getShort(text.innerText, 250);
          }
        }
      },
      focusView() {
        if (!this.isMore) {
          this.$router.push("/#present").catch(() => {});
        }
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";

  .present-section-container-all {
    padding-top: 10px;
    margin: 44px $space-0 44px $space-0;
    .main-title {
      justify-content: center;
      font-size: 42px;
    }
    .present-section-slide-title {
      text-align: center;
      color: $primary_color;
      font-size: 42px;
      font-weight: normal;
      height: 40px;
      display: flex;
      align-items: center;
      &.top {
        color: $primary_color_50;
        font-size: 20px;
      }
      .sub {
        text-transform: uppercase;
        color: $primary_color;
        font-size: 14px;
        font-family: $third_font;
        margin-bottom: 10px;
        text-align: left;
      }
    }
    .present-section-counter {
      position: absolute;
      z-index: 1;
      top: 10px;
      right: 10px;
      color: #fff;
      font-size: 16px;
      background-color: rgba(0, 0, 0, 0.3);
      padding: 1px 8px;
      border-radius: 12px;
    }
    .section-slide-page {
      width: 90%;
      padding: 10px;
      .present-section-container {
        &-btn {
          position: relative;
          overflow: hidden;
          &::after {
            position: absolute;
            visibility: hidden;
            top: 25px;
            right: -26px;
            content: "New";
            display: flex;
            justify-content: center;
            font-size: 10px;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            line-height: 20px;
            transform: rotate(45deg);
            width: 130px;
            background: $primary_color;
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
          }
          &.section-new {
            &::after {
              visibility: visible;
            }
          }
          &.section-new {
            position: relative;
          }
          .wrapper-btn {
            width: 70px;
            height: 70px;
            background: $primary_color;
            border-radius: 50%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 20;
            .btn-play {
              width: 50%;
              position: absolute;
              left: 55%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
        width: 95%;
        min-height: 300px;
        border-radius: 12px;
        background-color: #f6f6f6;
        box-shadow: 0px 3.46996px 6.93993px rgba(0, 0, 0, 0.25);
        margin: 0 auto;
        .present-section-image {
          min-height: 220px;
          width: 100%;
          object-fit: cover;
          border-top-left-radius: 11px;
          border-top-right-radius: 11px;
        }
        .desc {
          position: relative;
          padding: 15px;

          .container-text {
            min-height: 150px;
            height: 150px;
            overflow: hidden;
            .text {
              font-size: 14px;
            }
          }
          .icon-view-more {
            bottom: 5px;
            right: 5px;
            width: 30px;
            height: 30px;
            position: absolute;
            background: url("../../assets/img/icons/icon-view.svg");
            background-repeat: no-repeat;
          }
        }
      }
    }
    .slick-list {
      min-height: 470px;
    }
    .sign-up-event {
      width: 220px;
      margin: 0 auto;
      margin-top: 5px;
      border-radius: 20px;
      .sign-up-event-link {
        border-radius: 20px;
      }
    }
    .slick-prev,
    .slick-next {
      z-index: 1;
      height: 100%;
      width: 27px;
    }
    .slick-prev::before,
    .slick-next::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .slick-dots {
      position: relative;
      bottom: 0;
      padding-bottom: $mpadding;
      li button:before {
        font-size: 15px;
        color: $primary_color;
      }
      li.slick-active button:before {
        color: $primary_color;
      }
    }
  }
</style>
