<script>
  import Hls from "hls.js";
  export default {
    props: {
      url: String,
      bg: String,
    },
    data: function () {
      return {
        id: this.$global.fastID(),
        muted: true,
        loading: true,
        key: 0,
        videoDesktop: "https://cdn.models1a.com/31-5-2024/manifest.m3u8",
        videoMobile: "https://cdn.models1a.com/prueba/Mobile%0A%0A/mobileVideo.m3u8",
        videoFalls: "https://cdn.models1a.com/31-5-2024/input__video.mp4",
      };
    },
    computed: {
      screen() {
        return this.$store.state.screen;
      },
    },
    methods: {
      video: function () {
        const video = document.querySelector(`#video-${this.id}`);
        return video;
      },
      audio: function () {
        const video = this.video();
        this.key++;
        if (this.muted) {
          video.muted = false;
          this.muted = false;
        } else {
          video.muted = true;
          this.muted = true;
        }
      },
    },
    mounted: function () {
      const video = this.video();
      const videoSrc = screen.width >= 768 ? this.videoDesktop : this.videoMobile;
      const hls = new Hls();
      video.onplay = (event) => {
        this.loading = false;
      };
      if (Hls.isSupported()) {
        hls.loadSource(videoSrc);
        hls.attachMedia(video);
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = videoSrc;
      } else {
        video.src = this.videoFalls;
      }
    },
  };
</script>

<template>
  <div class="slide video top-video" :class="{ loading: loading }" v-if="url">
    <div class="slide-wrap video-wrap" :style="`background-image: url(${bg})`">
      <video class="homeVideo" width="100%" height="auto" playsinline muted autoplay loop :id="`video-${id}`">
        <source :src="url" type="video/mp4" />
        <track label="Español" kind="captions" srclang="es" src="" />
      </video>
    </div>
    <div class="top-video-controls">
      <button :key="key" class="control-button audio" @click="audio" v-if="$screen.width >= 768">
        <iconic v-if="muted" name="audio-off" />
        <iconic v-else name="audio" />
      </button>
    </div>
  </div>
</template>

<style lang="scss">
  .top-video {
    position: relative;
    &.loading .video-wrap {
      height: 100%;
    }
    .video-wrap {
      display: flex;
      background-size: cover;
      background-position: center center;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
      }
    }
    video {
      position: relative;
      z-index: 1;
      object-fit: cover;
    }
    &-controls {
      position: absolute;
      top: 100px;
      right: 40px;
      z-index: 2;
      .control-button {
        display: inline-block;
        width: 39px;
        height: 39px;
        background: $primary_color;
        color: $white;
        border: none;
        border-radius: 50%;
        font-size: 150%;
        line-height: 1;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: $primary_color_hover;
        }
      }
    }
    @media (min-width: 768px) {
      .homeVideo {
        height: 100vh;
      }
    }
    @media (max-width: 767px) {
      height: 75vh;
    }
  }
</style>
