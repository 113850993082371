<template>
  <section class="section training-section" id="training" v-bind="initData">
    <div v-if="info">
      <div class="training-section-title points_left points_nombl">
        <h2>{{ info.title }}</h2>
      </div>
      <div class="training-section-content">
        <div class="training-section-content-sidea">
          <div class="main">
            <div class="training-section-content-sidea-title">
              <h2>{{ info.title }}</h2>
            </div>
            <ul v-if="info.items">
              <li v-for="(item, idx) in info.items" :key="idx">{{ item }}</li>
            </ul>
            <div class="training-section-content-sidea-options">
              <a href="https://api.whatsapp.com/send?phone=573009135094&text=%C2%A1Hola!%20Models%201A,%20deseo%20m%C3%A1s%20informaci%C3%B3n" target="_blank">
                {{ info.talkToUs }}
                <iconic class="talkUs" name="arrow_right" />
              </a>
            </div>
          </div>
        </div>
        <div class="training-section-content-sideb">
          <div class="training-section-content-sideb-slideshow">
            <VueSlickCarousel v-bind="settings">
              <div v-for="(slide, idx) in slides" :key="idx">
                <picture>
                  <source :srcset="urlCloudFront(slide.desktop)" media="(min-width: 768px)" alt="Training image" />
                  <img class="training-section-content-sideb-image" :src="urlCloudFront(slide.mobile)" alt="Training image" preload="auto" />
                </picture>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import store from "../../store/index.js";
  import VueSlickCarousel from "vue-slick-carousel";
  import Iconic from "@/plugins/iconic/iconic.vue";
  export default {
    components: {
      VueSlickCarousel,
      Iconic,
    },
    data() {
      return {
        info: null,
        slides: [
          {
            desktop: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-03.webp",
            mobile: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-09.webp",
          },
          {
            desktop: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-01.webp",
            mobile: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-07.webp",
          },
          {
            desktop: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-11.webp",
            mobile: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-10.webp",
          },
          {
            desktop: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-06.webp",
            mobile: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-08.webp",
          },
          {
            desktop: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-05.webp",
            mobile: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-02.webp",
          },
          {
            desktop: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-04.webp",
            mobile: "https://new-web-site-bucket.s3.amazonaws.com/website/training/Propuesta%20P%C3%A1gina%20Web-12.webp",
          },
        ],
        settings: {
          arrows: false,
          dots: false,
          slidesToShow: 1,
          fade: true,
          autoplay: true,
          autoplaySpeed: 4000,
        },
      };
    },
    methods: {
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      getLanguage: async function () {
        if (store.getters["iflanguage"]) {
          let dataLang = await store.getters["dataLang"];
          this.info = dataLang.training;
        }
      },
    },
    computed: {
      initData: async function () {
        this.getLanguage();
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .training-section {
    position: relative;
    &-title > h2 {
      color: $primary_color;
      font: {
        size: 32px;
        family: $sec_font;
        weight: normal;
      }
      text-align: center;
    }
    &-content {
      position: relative;
      overflow: hidden;
      background-color: $primary_color;
      color: $white;
      z-index: 2;
      &-sidea {
        padding: $mpadding * 2 0;
        padding-left: 3%;
        background-image: url("https://new-web-site-bucket.s3.us-east-1.amazonaws.com/website/training/trainingFlag.png");
        background-position: center left;
        background-size: 59% 110%;
        background-repeat: no-repeat;
        z-index: 1;
        position: relative;
        font-size: 90%;
        ul {
          list-style-type: disc;
          padding-left: 20px;
          li {
            padding-bottom: $mpadding;
            padding-right: 120px;
          }
        }
        &-title > h2 {
          font-family: $sec_font;
          font-weight: normal;
          display: none;
        }
        &-options {
          margin-top: $mpadding * 2;
          width: max-content;
          border: 1px solid #fff;
          padding: 6px 15px;
          border-radius: 22px;
          .talkUs {
            font-size: 14px;
            position: relative;
            top: -1.3px;
          }
        }
      }
      &-sideb {
        position: absolute;
        right: 0;
        top: 50%;
        width: 60%;
        transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        height: 100%;
        &-slideshow {
          height: 100%;
        }
        div {
          height: 100%;
        }
        &-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 25%;
        }
      }
    }
    @media (min-width: $desktop_width) {
      &-title {
        display: none;
      }
      &-content {
        font-size: 120%;
        &-sidea {
          width: 95%;
          font-size: 100%;
          background-size: 62% 110%;
          ul {
            list-style-type: disc;
            padding-left: 20px;
            li {
              padding-bottom: $mpadding;
              padding-right: 480px;
            }
          }
          &-title > h2 {
            display: block;
            margin-bottom: $mpadding * 2;
            font-size: 42px;
          }
          &-options {
            .talkUs {
              font-size: 14px;
              position: relative;
              top: -2px;
            }
          }
        }
        &-sideb {
          width: 55%;
        }
      }
    }
  }
</style>
