<script>
  import store from "../../store/index.js";
  import PresentSectionMobile from "./PresentSectionMobile.vue";
  import PresentSectionDesktop from "./PresentSectionDesktop.vue";
  export default {
    components: {
      PresentSectionMobile,
      PresentSectionDesktop,
    },
    data() {
      return {
        observer: null,
        isInView: false,
      };
    },
    computed: {
      screen() {
        return store.state.screen.width;
      },
    },
    methods: {
      handleIntersect(entries) {
        entries.forEach((entry) => {
          this.isInView = entry.isIntersecting;
        });
      },
    },
    mounted() {
      this.observer = new IntersectionObserver(this.handleIntersect, {
        root: null,
        rootMargin: "5px",
        threshold: 0,
      });

      this.$nextTick(() => {
        this.observer.observe(this.$refs.presentSections);
      });
    },
    beforeDestroy() {
      if (this.observer) {
        this.observer.unobserve(this.$refs.presentSections);
        this.observer.disconnect();
      }
    },
  };
</script>

<template>
  <div class="presentSections" ref="presentSections">
    <PresentSectionDesktop :isInView="isInView" v-if="screen >= 768" />
    <PresentSectionMobile :isInView="isInView" v-if="screen <= 767" />
  </div>
</template>