<script>
  import VueSlickCarouselc from "vue-slick-carousel";
  import store from "../../store/index.js";
  import { mapActions } from "vuex";

  export default {
    components: { VueSlickCarouselc },
    data: function () {
      return {
        data: null,
        language: null,

        settings: {
          arrows: true,
          dots: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          adaptiveHeight: true,
          cancelable: false,
          centerMode: false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                arrows: true,
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                variableWidth: true,
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 426,
              settings: {
                arrows: false,
                variableWidth: true,
                slidesToShow: 2,
              },
            },
          ],
        },
      };
    },
    methods: {
      ...mapActions("gallery", ["getGalleryVideos"]),
      urlCloudFront(link) {
        return this.$global.newUrl(link);
      },
      getLang: async function () {
        if (store.getters["iflanguage"]) {
          this.language = await store.getters["localeLang"];
        }
      },
      urlEncodeB64: function (url) {
        return btoa(url);
      },
    },

    computed: {
      initData: async function () {
        await this.getLang();
      },
    },
    async beforeMount() {
      this.data = await this.getGalleryVideos();
      this.data.reverse();
    },
  };
</script>

<template>
  <section class="shortfilms section section-carousel" id="galleryVideos" v-bind="initData">
    <div class="section-carousel-container" v-if="data && language">
      <div class="section-carousel-wrap">
        <div class="section-carousel-title">
          <h3 class="section-carousel-title-sub sec-font">{{ language.words.videiosGallery.title }}</h3>
        </div>
        <div class="section-carousel-filter">
          <div class="section-carousel-filter-content main">
            <VueSlickCarouselc class="section-carousel-filter-slide" v-if="data" v-bind="settings">
              <div class="section-carousel-filter-category" v-for="(cat, cidx) in data" :key="cidx">
                <router-link
                  class="nombre"
                  :to="{
                    path: 'watch',
                    query: { v: urlEncodeB64(cat.videoLink), backto: '/#galleryVideos', size: true },
                  }"
                  inline-b>
                  <div class="section-carousel-filter-card bestseller" :class="cat.isNewVideo ? 'section-new' : ''" prevent>
                    <div class="section-carousel-filter-img-wrap">
                      <img :src="urlCloudFront(cat.imageLink)" :alt="cat.alt" class="section-carousel-filter-img" loading="lazy" />
                    </div>
                    <div class="section-carousel-filter-img-title" ellipsis>
                      {{ cat.alt }}
                    </div>
                  </div>
                </router-link>
              </div>
            </VueSlickCarouselc>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
  @import "@/assets/styles/vars.scss";

  .shortfilms {
    .section-carousel {
      &-container {
        padding: 44px 0;
        text-align: center;
        background-image: linear-gradient(to top, #f6f6f6 30%, #fff 70%);
      }
      &-header {
        height: 240px;
        margin-bottom: 40px;
      }
      &-title {
        &-top {
          font-size: 20px;
          font-weight: bold;
          color: $primary_color_50;
        }
        &-sub {
          font-size: 42px;
          color: $primary_color;
          font-weight: normal;
        }
      }
      &-filter {
        .slick-prev,
        .slick-next {
          z-index: 1;
          height: 100%;
          width: 60px;
          height: 70px;
        }
        .slick-prev::before,
        .slick-next::before {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          content: "";
        }
        .slick-prev {
          left: -70px;
          background: url("https://new-web-site-bucket.s3.amazonaws.com/website/gallery/Component%2046.svg") no-repeat center;
        }
        .slick-next {
          right: -70px;
          background: url("https://new-web-site-bucket.s3.amazonaws.com/website/gallery/Component%2045.svg") no-repeat center;
        }
        &-card {
          height: auto;
          box-shadow: 0 0 10px $alto;
          padding: 0px 5px;
          max-width: 100%;
          margin: $mpadding/2;
          display: inline-block;
          overflow: hidden;
          background-color: $white;
          color: $primary_color;
          .section-carousel-filter-img-title {
            font-size: 20px;
            margin-top: 7px;
            text-align: center;
            font-family: BebasKai, sans-serif;
            margin-bottom: 5px;
          }
        }
        &-img-wrap {
          max-width: 100%;
          min-height: 315px;
          margin-top: 2px 0;
          background-color: $body_color;
          overflow: hidden;
        }
        &-img {
          width: 100%;
          height: 100%;
          pointer-events: none;
          object-fit: cover;
        }
        &-card,
        &-img-wrap {
          border-radius: 12px;
        }
        &-img-tittle {
          font-size: 20px;
          margin-top: 7px;
          text-align: center;
          font-family: $sec_font;
        }
      }

      @media (min-width: 769px) {
        &-title {
          &-top {
            font: {
              size: 30px;
              weight: bold;
            }
          }
          &-sub {
            font-size: 42px;
          }
        }
        &-filter {
          &-slide {
            margin: 0 60px;
          }
        }
      }
      @media (min-width: $desktop_width) {
        &-title {
          &-top {
            font: {
              size: 30px;
              weight: bold;
            }
          }
          &-sub {
            font-size: 42px;
          }
        }
        &-filter {
          &-slide {
            margin: 0 60px;
          }
        }
      }
    }
    .section-new {
      position: relative;
    }
    .nombre {
      width: 202px;
      height: 375px;
      margin: 5px;
    }

    .bestseller {
      position: relative;
      &::after {
        position: absolute;
        visibility: hidden;
        top: 26px;
        right: -25px;
        content: "New";
        font-size: 10px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        line-height: 20px;
        transform: rotate(45deg);
        width: 120px;
        background: $primary_color;
        box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
      }
      &.section-new {
        &::after {
          visibility: visible;
        }
      }
    }
  }
</style>
