import React from "react";
import styles from "./closeBtn.module.scss";

function CloseBtn({ closeFunction = () => {}, lastPage = false }) {
  const configPositioning = {
    "--left-positioning": lastPage ? "unset" : "10px",
  };

  return (
    <div className={styles.closeBtnReact} style={configPositioning} onClick={closeFunction}>
      <span className={styles.closeBtnReact__icon}>✕</span>
    </div>
  );
}

export default CloseBtn;
