<template>
  <section class="section-locations section" ref="location" id="locations" v-bind="initData">
    <div class="section-locations-container" v-if="info">
      <div class="section-locations-title-out points_right">
        <h2 class="section-locations-title-out-top third-font" bold>{{ info.title }}</h2>
        <h3 class="section-locations-title-out-sub sec-font">{{ info.subtitle }}</h3>
      </div>
      <div class="section-locations-content">
        <div class="section-locations-content-wrap">
          <div class="main">
            <h3 class="section-locations-content-title sec-font">{{ info.subtitle2 }}</h3>
            <div class="section-locations-content-locations">
              <div class="section-locations-content-locations-cities" v-if="info.cities">
                <div class="section-locations-content-locations-cities-city" v-for="(city, cid) in info.cities" :key="cid" ref="map">
                  <div :class="`city-card city-card-inner ${city.video ? 'hasVideo' : ''}`" block @click="() => getRouterLink(city)">
                    <div class="face city-hidden">
                      <iconic name="btn_play" />
                      <span>play</span>
                    </div>
                    <div class="face city-front">
                      <img src="../../assets/img/icons/Vector (1).svg" alt="" loading="lazy" />
                      <span>{{ city.city }}</span>
                    </div>
                    <div class="face city-back">
                      <iconic name="btn_play" />
                      <span>{{ info.watchVideo }}</span>
                    </div>
                    <div class="line"></div>
                  </div>
                </div>
              </div>
              <div class="section-locations-content-locations-map">
                <div class="colombian">
                  <img class="colombian-img" src="https://new-web-site-bucket.s3.amazonaws.com/website/locations/mapaRojo.png" alt="map Colombia" loading="lazy" />
                </div>
                <div class="world">
                  <img class="world-img" src="../../assets/img/icons/MapaMundi (3).svg" alt="world" loading="lazy" />
                </div>
                <div class="map-cities">
                  <div class="map-city"><div class="city-line"></div></div>
                  <div class="map-city"><div class="city-line"></div></div>
                  <div class="map-city"><div class="city-line"></div></div>
                  <div class="map-city"><div class="city-line"></div></div>
                  <div class="map-city"><div class="city-line"></div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import store from "../../store/index.js";
  import { mapGetters } from "vuex";
  export default {
    data: function () {
      return {
        info: null,
      };
    },
    computed: {
      ...mapGetters(["getLocale"]),
      initData: async function () {
        this.getLanguage();
      },
      screen() {
        return store.state.screen.width;
      },
    },
    methods: {
      getLanguage: async function () {
        if (store.getters["iflanguage"]) {
          let dataLang = await store.getters["dataLang"];
          this.info = dataLang.locations;
          this.info = { ...this.info, watchVideo: dataLang.banners.mobileBanners[0].descriptions[6] };
        }
      },
      getRouterLink(city) {
        if (!city) {
          return;
        }
        if (city.city === "GLOBAL") {
          window.location.href = `/global?lang=${this.getLocale}`;
          return;
        }
        if (!city.video) {
          return;
        }

        const params = {
          path: "watch",
          query: { v: this.urlEncodeB64(video), backto: "/" },
        };

        this.$router.push(params);
      },
      urlEncodeB64: function (url) {
        return btoa(url);
      },
      intersectionHandler(entries) {
        const { target, intersectionRatio, isIntersecting } = entries[0];
        const isVisible = intersectionRatio !== 0 && intersectionRatio !== 1 && isIntersecting;
        isVisible ? this.sectionActive(target) : target.classList.remove("section-active");
      },
      sectionActive(target) {
        target.classList.add("section-active");
        if (this.screen > 768) return;
        setTimeout(() => {
          target.classList.remove("section-active");
        }, 1000);
      },
      mapAnimation: function () {
        setInterval(() => {
          const mapContainer = document.querySelectorAll(".hasVideo");
          mapContainer.forEach((item) => item.classList.toggle("play"));
        }, 4000);
      },
    },
    mounted() {
      const observer = new IntersectionObserver(this.intersectionHandler, { threshold: 0.6 });
      observer.observe(this.$refs.location);
      this.mapAnimation();
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  .section-locations {
    &-title-out {
      text-align: center;
      margin-bottom: 20px;
      &-top {
        color: $primary_color_50;
        font-size: 20px;
      }
      &-sub {
        color: $primary_color;
        font-size: 32px;
        font-weight: normal;
      }
    }
    &-content {
      position: relative;
      background-color: $footer_bg;
      padding-top: 10px;
      &-title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: $primary_color;
        font-size: 32px;
        font-weight: normal;
      }
      &-locations {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $mpadding * 2;
        padding-left: 0px;
        overflow: hidden;
        &-cities {
          position: relative;
          display: flex;
          flex-direction: column;
          z-index: 1;
          background-color: $footer_bg;
          &:before {
            content: "";
            left: -$mpadding * 2;
            top: 0;
            bottom: 0;
            background-color: $footer_bg;
            width: 50%;
          }
          &-city {
            height: 35px;
            overflow: hidden;
            .face {
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                height: 15px;
              }
              .iconic {
                font-size: 13px;
                position: static;
              }
            }
            .city-front,
            .city-back {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              width: 130px;
            }
            .city-card-inner {
              position: relative;
              @include Flex();
              background-color: $primary_color;
              padding: $mpadding/2;
              width: 120px;
              border-radius: 9px;
              text-align: center;
              color: $footer_bg;
              cursor: pointer;
              i {
                position: absolute;
                left: $mpadding/2;
                font-size: 130%;
              }
              span {
                margin-left: $mpadding;
                font-family: $sec_font;
              }
              .city-front {
                animation: fade-in 1s forwards;
              }
              .city-back {
                animation: fade-out 1s forwards;
              }
            }
            .city-hidden {
              visibility: hidden;
            }

            .city-card-inner.play {
              .city-front {
                animation: fade-out 1s forwards;
              }
              .city-back {
                animation: fade-in 1s forwards;
              }
            }
            @keyframes fade-out {
              0% {
                opacity: 1;
                transform: translateY(0);
              }
              50% {
                opacity: 0;
                transform: translateY(-50%);
              }
              100% {
                opacity: 0;
                transform: translateY(-100%);
                display: none;
              }
            }

            @keyframes fade-in {
              0% {
                opacity: 0;
                transform: translateY(100%);
                display: flex;
              }
              50% {
                opacity: 0;
                transform: translateY(50%);
              }
              100% {
                opacity: 1;
                transform: translateY(0);
              }
            }
            margin: $mpadding $mpadding $mpadding * 1.5 0;
            &:nth-child(1),
            &:nth-child(4) {
              margin-left: $mpadding;
            }
          }
        }
        &-map {
          position: relative;
          display: flex;
          justify-content: space-between;
          max-width: 1400px;
          width: 100%;
          .colombian-img {
            height: 300px;
            padding-left: 100%;
          }
          .world-img {
            padding-left: 60%;
            height: 300px;
          }
          .map-city {
            width: 10px;
            height: 10px;
            background: #bd0909;
            position: absolute;
            border-radius: 50%;
            .city-line {
              position: absolute;
              color: #bd0909;
              transition: width 0.3s ease-in-out;
              width: 0;
              right: 0;
              border-right: none;
              border-radius: $mradius/2;
            }
            &:nth-child(4),
            &:nth-child(5) {
              .city-line::before {
                content: "";
                position: absolute;
                border-radius: 50%;
                left: -10px;
                top: 0px;
                width: 10px;
                height: 10px;
                animation: radar2 2s infinite;
              }
            }

            &:nth-child(1) {
              top: 28%;
              left: 31%;
              .city-line {
                border-top: solid 2px;
                height: 70px;
                bottom: 0;
              }
            }
            &:nth-child(2) {
              top: 51%;
              left: 33%;
              .city-line {
                border-top: solid 2px;
                height: 10px;
                bottom: 0;
              }
            }
            &:nth-child(3) {
              top: 25%;
              left: 38%;
              .city-line {
                border-bottom: solid 2px;
                height: 80px;
                top: 0;
              }
            }
            &:nth-child(4) {
              top: 52%;
              left: 38%;
              .city-line {
                border-bottom: solid 2px;
                height: 82px;
                top: 0;
              }
            }
            &:nth-child(5) {
              top: 35%;
              left: 91%;
              .city-line {
                border-bottom: solid 2px;
                height: 82px;
                top: 0;
              }
            }
          }
        }
      }
    }

    &.section-active {
      .map-city {
        .city-line {
          border-right: solid 2px;
          left: calc(-100vw + 5px);
          width: 100vw;
        }
        @for $i from 1 through 5 {
          &:nth-child(#{$i}) {
            .city-line {
              transition-delay: $i * (0.6s/10);
            }
          }
        }
      }
    }

    @media (min-width: 1130px) {
      &-title-out {
        &-top {
          font-size: 30px;
        }
        &-sub {
          font-size: 42px;
        }
      }
      &-content {
        &-title {
          font-size: 42px;
        }
        &-locations {
          &-cities {
            &-city {
              margin: $mpadding $mpadding * 2 $mpadding * 2 $mpadding * 2;
              margin-right: 0;
              margin-left: 60px;
              &:nth-child(1),
              &:nth-child(4) {
                margin-left: $mpadding * 2;
                margin-left: 60px;
              }
              a {
                width: 126px;
              }
            }
          }
          &-map {
            .map-city {
              &:nth-child(1) {
                .city-line {
                  height: 110px;
                }
              }
              &:nth-child(2) {
                .city-line {
                  border-top: solid 2px;
                  height: 100px;
                  bottom: 0;
                }
              }
              &:nth-child(3) {
                .city-line {
                  height: 70px;
                }
              }
              &:nth-child(4) {
                .city-line {
                  height: 69px;
                }
              }
              &:nth-child(5) {
                .city-line {
                  height: 199px;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 1129px) {
      &-title-out {
        &-top {
          font-size: 30px;
        }
        &-sub {
          font-size: 42px;
        }
      }
      &-content {
        &-title {
          font-size: 42px;
        }
        &-locations {
          &-cities {
            &-city {
              margin: 10px 0;
              margin-right: 0;
              margin-left: 80px;
              &:nth-child(1),
              &:nth-child(4) {
                margin-left: $mpadding * 2;
                margin-left: 80px;
              }
              a {
                width: 126px;
              }
            }
          }
          .map-city {
            .city-line {
              position: absolute;
              color: #bd0909;
              transition: width 0.3s ease-in-out;
              width: 0;
              right: 0;
              border-right: none;
              border-radius: $mradius/2;
            }
            &:nth-child(1) {
              top: 30%;
              left: 28%;
              .city-line {
                border-top: solid 2px;
                height: 70px;
                bottom: 0;
              }
            }
            &:nth-child(2) {
              top: 51%;
              left: 29%;
              .city-line {
                border-top: solid 2px;
                height: 10px;
                bottom: 0;
              }
            }
            &:nth-child(3) {
              top: 23%;
              left: 34%;
              .city-line {
                border-bottom: solid 2px;
                height: 80px;
                top: 0;
              }
            }
            &:nth-child(4) {
              top: 50%;
              left: 34%;
              .city-line {
                border-bottom: solid 2px;
                height: 82px;
                top: 0;
              }
            }
            &:nth-child(5) {
              top: 33%;
              left: 86%;
              .city-line {
                height: 82px;
                top: 0;
              }
            }
          }
          &-map {
            .world-img {
              height: 250px;
              // padding-left: 50%;
            }
            .colombian-img {
              // padding-left: %;
              height: 250px;
            }
            .map-city {
              &:nth-child(1) {
                .city-line {
                  height: 70px;
                }
              }
              &:nth-child(2) {
                .city-line {
                  border-top: solid 2px;
                  height: 70px;
                  bottom: 0;
                }
              }
              &:nth-child(3) {
                .city-line {
                  height: 70px;
                }
              }
              &:nth-child(4) {
                .city-line {
                  height: 57px;
                }
              }
              &:nth-child(5) {
                .city-line {
                  height: 155px;
                }
              }
            }
          }
        }
      }
    }

    @media (max-width: 1075px) {
      &-title-out {
        &-top {
          font-size: 30px;
        }
        &-sub {
          font-size: 42px;
        }
      }
      &-content {
        &-title {
          font-size: 42px;
        }
        &-locations {
          &-cities {
            &-city {
              margin: 10px 0;
              margin-right: 0;
              margin-left: 80px;
              &:nth-child(1),
              &:nth-child(4) {
                margin-left: $mpadding * 2;
                margin-left: 80px;
              }
              a {
                width: 126px;
              }
            }
          }
          .map-city {
            .city-line {
              position: absolute;
              color: #bd0909;
              transition: width 0.3s ease-in-out;
              width: 0;
              right: 0;
              border-right: none;
              border-radius: $mradius/2;
            }
            &:nth-child(1) {
              top: 30%;
              left: 30%;
              .city-line {
                border-top: solid 2px;
                height: 70px;
                bottom: 0;
              }
            }
            &:nth-child(2) {
              top: 51%;
              left: 31%;
              .city-line {
                border-top: solid 2px;
                height: 10px;
                bottom: 0;
              }
            }
            &:nth-child(3) {
              top: 23%;
              left: 36.5%;
              .city-line {
                border-bottom: solid 2px;
                height: 80px;
                top: 0;
              }
            }
            &:nth-child(4) {
              top: 50%;
              left: 36%;
              .city-line {
                border-bottom: solid 2px;
                height: 82px;
                top: 0;
              }
            }
            &:nth-child(5) {
              top: 33%;
              left: 89%;
              .city-line {
                height: 82px;
                top: 0;
              }
            }
          }
          &-map {
            .world-img {
              height: 250px;
              // padding-left: 50%;
            }
            .colombian-img {
              // padding-left: %;
              height: 250px;
            }
            .map-city {
              &:nth-child(1) {
                .city-line {
                  height: 70px;
                }
              }
              &:nth-child(2) {
                .city-line {
                  border-top: solid 2px;
                  height: 70px;
                  bottom: 0;
                }
              }
              &:nth-child(3) {
                .city-line {
                  height: 70px;
                }
              }
              &:nth-child(4) {
                .city-line {
                  height: 57px;
                }
              }
              &:nth-child(5) {
                .city-line {
                  height: 155px;
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 1000px) {
      &-title-out {
        &-top {
          font-size: 30px;
        }
        &-sub {
          font-size: 42px;
        }
      }
      &-content {
        &-title {
          font-size: 42px;
        }
        &-locations {
          &-cities {
            &-city {
              margin: 10px 0;
              margin-right: 0;
              margin-left: 80px;
              &:nth-child(1),
              &:nth-child(4) {
                margin-left: $mpadding * 2;
                margin-left: 80px;
              }
              a {
                width: 126px;
              }
            }
          }
          .map-city {
            .city-line {
              position: absolute;
              color: #bd0909;
              transition: width 0.3s ease-in-out;
              width: 0;
              right: 0;
              border-right: none;
              border-radius: $mradius/2;
            }
            &:nth-child(1) {
              top: 30%;
              left: 30%;
              .city-line {
                border-top: solid 2px;
                height: 70px;
                bottom: 0;
              }
            }
            &:nth-child(2) {
              top: 51%;
              left: 31%;
              .city-line {
                border-top: solid 2px;
                height: 10px;
                bottom: 0;
              }
            }
            &:nth-child(3) {
              top: 23%;
              left: 36.5%;
              .city-line {
                border-bottom: solid 2px;
                height: 80px;
                top: 0;
              }
            }
            &:nth-child(4) {
              top: 50%;
              left: 36%;
              .city-line {
                border-bottom: solid 2px;
                height: 82px;
                top: 0;
              }
            }
            &:nth-child(5) {
              top: 33%;
              left: 89%;
              .city-line {
                height: 82px;
                top: 0;
              }
            }
          }
          &-map {
            .world-img {
              height: 250px;
              padding-left: 40%;
            }
            .colombian-img {
              padding-left: 80%;
              height: 250px;
            }
            .map-city {
              &:nth-child(1) {
                .city-line {
                  height: 70px;
                }
              }
              &:nth-child(2) {
                .city-line {
                  border-top: solid 2px;
                  height: 70px;
                  bottom: 0;
                }
              }
              &:nth-child(3) {
                .city-line {
                  height: 70px;
                }
              }
              &:nth-child(4) {
                .city-line {
                  height: 57px;
                }
              }
              &:nth-child(5) {
                .city-line {
                  height: 155px;
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 775px) {
      &-title-out {
        &-top {
          font-size: 30px;
        }
        &-sub {
          font-size: 42px;
        }
      }
      &-content {
        &-title {
          font-size: 42px;
        }
        &-locations {
          &-cities {
            &-city {
              margin: 10px 0;
              margin-right: 0;
              margin-left: 70px;
              &:nth-child(1),
              &:nth-child(4) {
                margin-left: $mpadding * 2;
                margin-left: 70px;
              }
              a {
                width: 126px;
              }
            }
          }
          .map-city {
            .city-line {
              position: absolute;
              color: #bd0909;
              transition: width 0.3s ease-in-out;
              width: 0;
              right: 0;
              border-right: none;
              border-radius: $mradius/2;
            }
            &:nth-child(1) {
              top: 42%;
              left: 62%;
              .city-line {
                border-top: solid 2px;
                height: 70px;
                bottom: 0;
              }
            }
            &:nth-child(2) {
              top: 42%;
              left: 62%;
              .city-line {
                border-top: solid 2px;
                height: 10px;
                bottom: 0;
              }
            }
            &:nth-child(3) {
              top: 42%;
              left: 62%;
              .city-line {
                border-bottom: solid 2px;
                height: 80px;
                top: 0;
              }
            }
            &:nth-child(4) {
              top: 42%;
              left: 62%;
              .city-line {
                border-bottom: solid 2px;
                height: 82px;
                top: 0;
              }
            }
            &:nth-child(5) {
              top: 33%;
              left: 75%;
              .city-line {
                height: 82px;
                top: 0;
              }
            }
          }
          &-map {
            .world-img {
              height: 230px;
              padding-left: 40%;
            }
            .colombian-img {
              display: none;
            }
            .map-city {
              &:nth-child(1) {
                .city-line {
                  height: 100px;
                }
              }
              &:nth-child(2) {
                .city-line {
                  border-top: solid 2px;
                  height: 46px;
                  bottom: 0;
                }
              }
              &:nth-child(3) {
                .city-line {
                  height: 20px;
                }
              }
              &:nth-child(4) {
                .city-line {
                  height: 74px;
                }
              }
              &:nth-child(5) {
                .city-line {
                  height: 155px;
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 720px) {
      &-title-out {
        &-top {
          font-size: 30px;
        }
        &-sub {
          font-size: 32px;
        }
      }
      &-content {
        &-title {
          font-size: 32px;
        }
        &-locations {
          .map-city {
            &:nth-child(1) {
              top: 42%;
              left: 64%;
            }
            &:nth-child(2) {
              top: 42%;
              left: 64%;
            }
            &:nth-child(3) {
              top: 42%;
              left: 64%;
            }
            &:nth-child(4) {
              top: 42%;
              left: 64%;
            }
            &:nth-child(5) {
              top: 33%;
              left: 80%;
            }
          }
          &-map {
            .world-img {
              height: 230px;
              padding-left: 40%;
            }
            .map-city {
              &:nth-child(1) {
                .city-line {
                  height: 100px;
                }
              }
              &:nth-child(2) {
                .city-line {
                  border-top: solid 2px;
                  height: 46px;
                  bottom: 0;
                }
              }
              &:nth-child(3) {
                .city-line {
                  height: 20px;
                }
              }
              &:nth-child(4) {
                .city-line {
                  height: 74px;
                }
              }
              &:nth-child(5) {
                .city-line {
                  height: 155px;
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 600px) {
      &-title-out {
        &-top {
          font-size: 30px;
        }
        &-sub {
          font-size: 32px;
        }
      }
      &-content {
        &-title {
          font-size: 32px;
          width: 100%;
        }
        &-locations {
          .map-city {
            &:nth-child(1) {
              top: 42%;
              left: 55%;
            }
            &:nth-child(2) {
              top: 42%;
              left: 55%;
            }
            &:nth-child(3) {
              top: 42%;
              left: 55%;
            }
            &:nth-child(4) {
              top: 42%;
              left: 55%;
            }
            &:nth-child(5) {
              top: 33%;
              left: 77%;
            }
          }
          &-map {
            .world-img {
              height: 230px;
              padding-left: 20%;
            }
            .map-city {
              &:nth-child(1) {
                .city-line {
                  height: 100px;
                }
              }
              &:nth-child(2) {
                .city-line {
                  border-top: solid 2px;
                  height: 46px;
                  bottom: 0;
                }
              }
              &:nth-child(3) {
                .city-line {
                  height: 20px;
                }
              }
              &:nth-child(4) {
                .city-line {
                  height: 74px;
                }
              }
              &:nth-child(5) {
                .city-line {
                  height: 155px;
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 450px) {
      &-content {
        &-title {
          font-size: 32px;
          width: 100%;
        }
        &-locations {
          &-cities {
            &-city {
              margin: 10px 0;
              margin-right: 0;
              margin-left: 30px;
              &:nth-child(1),
              &:nth-child(4) {
                margin-left: $mpadding * 2;
                margin-left: 30px;
              }
              a {
                width: 126px;
              }
            }
          }
          .map-city {
            &:nth-child(1) {
              top: 42%;
              left: 49%;
            }
            &:nth-child(2) {
              top: 42%;
              left: 49%;
            }
            &:nth-child(3) {
              top: 42%;
              left: 49%;
            }
            &:nth-child(4) {
              top: 42%;
              left: 49%;
            }
            &:nth-child(5) {
              top: 33%;
              left: 77%;
            }
          }
          &-map {
            .world-img {
              height: 210px;
              padding-left: 10%;
            }
            .map-city {
              &:nth-child(1) {
                .city-line {
                  height: 100px;
                }
              }
              &:nth-child(2) {
                .city-line {
                  border-top: solid 2px;
                  height: 46px;
                  bottom: 0;
                }
              }
              &:nth-child(3) {
                .city-line {
                  height: 20px;
                }
              }
              &:nth-child(4) {
                .city-line {
                  height: 74px;
                }
              }
              &:nth-child(5) {
                .city-line {
                  height: 155px;
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 400px) {
      &-content {
        &-title {
          font-size: 32px;
        }
        &-locations {
          &-cities {
            &-city {
              margin: 10px 0;
              margin-right: 0;
              margin-left: 20px;
              &:nth-child(1),
              &:nth-child(4) {
                margin-left: $mpadding * 2;
                margin-left: 20px;
              }
              a {
                width: 126px;
              }
            }
          }
          .map-city {
            &:nth-child(1) {
              top: 42%;
              left: 44%;
            }
            &:nth-child(2) {
              top: 42%;
              left: 44%;
            }
            &:nth-child(3) {
              top: 42%;
              left: 44%;
            }
            &:nth-child(4) {
              top: 42%;
              left: 44%;
            }
            &:nth-child(5) {
              top: 33%;
              left: 77%;
            }
          }
          &-map {
            .world-img {
              height: 160px;
              padding-left: 5%;
            }
            .map-city {
              &:nth-child(1) {
                .city-line {
                  height: 100px;
                }
              }
              &:nth-child(2) {
                .city-line {
                  border-top: solid 2px;
                  height: 46px;
                  bottom: 0;
                }
              }
              &:nth-child(3) {
                .city-line {
                  height: 20px;
                }
              }
              &:nth-child(4) {
                .city-line {
                  height: 74px;
                }
              }
              &:nth-child(5) {
                .city-line {
                  height: 155px;
                }
              }
            }
          }
        }
      }
    }
  }
</style>
