<template>
  <router-link :class="'primary-button button ' + (mclass ? mclass : '')" :to="{ path: '/signup#signup' }">
    {{ title ? title : "Regístrate" }}
  </router-link>
</template>

<script>
export default {
  props: {
    mclass: String,
    hash: String,
    title: String,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";
.primary-button {
  white-space: nowrap;

  transition: 0.3s ease-in-out;
  &:hover {
    background-color: $primary_color_hover;
  }
}
</style>
