<script>
  export default {
    props: {
      text: {
        type: String,
        required: true,
      },
      action: {
        type: Function,
        required: true,
      },
    },
  };
</script>
<template>
  <button class="magazineBtn" @click="action">
    {{ text }}
  </button>
</template>

<style lang="scss">
  :root {
    --angle: 0deg;
  }
  .magazineBtn {
    width: 200px;
    height: 50px;
    background: linear-gradient(to left top, #a90606 50%, #bd0909 50%);
    border: none;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    font-family: $first_font;
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.5s;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.35);
    border-radius: 40px;

    &:hover {
      transform: rotate(-2deg) scale(1.1);
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
      padding: 2px;
      background: conic-gradient(from var(--angle), #f6e58d 10%, transparent 20%, transparent 80%, #f6e58d 90%);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
      animation: borderRotate 2.8s linear infinite forwards;
    }
  }

  @keyframes borderRotate {
    100% {
      --angle: 360deg;
    }
  }
</style>
